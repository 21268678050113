<template>
  <ul>
    <li
      v-for="(item, index) in items"
      :key="`${item.id}${index}`"
      class="flex flex-wrap items-center py-5 pl-8 cursor-pointer border-b border-dashed border-grey-darker-200"
      :class="{
        'purple-light-bg purple-text': activeItem == item.value
      }"
      @click="setItem(item.value)"
    >
      <span>{{ item.title }}</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: [ 'items', 'activeItem' ],
    methods: {
      setItem(data){
        this.$emit('setItem', data)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>